import React from 'react';

function EmailPage() {
  return (
    <div>
      <h1>Welcome to the Email Page</h1>
      <p>This is the email of our SPA.</p>
    </div>
  );
}

export default EmailPage;
