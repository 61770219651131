import React, { useState, useEffect } from 'react';

function Footer() {
  return (
    <div>
        <footer className="py-5 bg-dark">
            <div className="container"><p className="m-0 text-center text-white">Copyright &copy; sed.sh 2023</p></div>
        </footer>
    </div>
  );
}

export default Footer;
