import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';


function HomePage() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
      // Fetch the user's authentication status when the component mounts
      checkAuthStatus();
    }, []);
  
    async function checkAuthStatus() {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    }
  
    useEffect(() => {
        const hash = window.location.hash;
        const parameter = hash.substring(1);
        const params = new URLSearchParams(parameter);
        const idToken = params.get('id_token');

        function getCookie(cookieName) {
            var cookies = document.cookie.split('; ');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].split('=');
                var name = decodeURIComponent(cookie[0]);
                var value = decodeURIComponent(cookie[1]);
                if (name === cookieName) {
                    return value;
                }
            }
            return null;
        }

        if (idToken) {
            document.cookie = "id_token=" + idToken + "; path=/";
            console.log(idToken);
            window.location.replace("https://sed.sh/index.html");
        }

        const signup_button = document.getElementById('signup-button');
        const login_button = document.getElementById('login-button');
        const logout_button = document.getElementById('logout-button');
        const idTokenCookie = getCookie('id_token');
        if (idTokenCookie) {
            if (signup_button) signup_button.style.visibility = 'hidden';
            if (login_button) login_button.style.visibility = 'hidden';
            if (logout_button) logout_button.style.visibility = 'visible';
        } else {
            if (signup_button) signup_button.style.visibility = 'visible';
            if (login_button) login_button.style.visibility = 'visible';
            if (logout_button) logout_button.style.visibility = 'hidden';
        }
    }, []);

    return (
        <div>            
            <header className="py-5">
              <div className="container px-lg-5">
                  <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                      <div className="m-4 m-lg-5">
                          <h1 className="display-5 fw-bold">sed.sh: The Swiss Army Knife for Developers</h1>
                          <p className="fs-4">Discover the power of sed.sh, the all-in-one platform offering a range of services including URL shortening, random email inboxes, file sharing, and much more. Sign up now to experience seamless convenience and unlock a world of versatile tools at your fingertips.</p>
                          <p className="fs-4">                            
                            {isAuthenticated ? (
                                <Link to="/logout" className="anonymous-link">
                                    <span className="btn btn-primary btn-lg" id="logout-button">Logout</span>
                                </Link>
                            ) : (
                                <>
                                <Link to="/signup" className="anonymous-link">
                                    <span className="btn btn-primary btn-lg" id="signup-button">Signup</span>
                                </Link>
                                &nbsp;
                                <Link to="/login" className="anonymous-link">
                                    <span className="btn btn-primary btn-lg" id="login-button">Login</span>
                                </Link>
                                </>
                            )}
                          </p>
                      </div>
                  </div>
              </div>
            </header>

            <section className="pt-4">
              <div className="container px-lg-5">
                  <div className="row gx-lg-5">
                      <div className="col-lg-6 col-xxl-4 mb-5 hover-bold">
                          <Link to="/short" className="anonymous-link">
                              <div className="card bg-light border-0 h-100">
                                  <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4"><i className="bi bi-collection"></i></div>
                                      <h2 className="fs-4 fw-bold">Short</h2>
                                      <p className="mb-0">Simplify URL sharing</p>
                                  </div>
                              </div>
                          </Link>
                      </div>  
                      <div className="col-lg-6 col-xxl-4 mb-5">
                          <div className="card bg-light border-0 h-100">
                              <Link to="/email" className="anonymous-link">
                                  <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4"><i className="bi bi-cloud-download"></i></div>
                                      <h2 className="fs-4 fw-bold">Inbox</h2>
                                      <p className="mb-0">Random inbox generator</p>
                                  </div>
                              </Link>
                          </div>
                      </div>
                      <div className="col-lg-6 col-xxl-4 mb-5">
                          <div className="card bg-light border-0 h-100">
                              <Link to="/route" className="anonymous-link">
                                  <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4"><i className="bi bi-code"></i></div>
                                      <h2 className="fs-4 fw-bold">Route</h2>
                                      <p className="mb-0">Seamless data routing</p>
                                  </div>
                              </Link>
                          </div>
                      </div>

{/*                      
                      <div className="col-lg-6 col-xxl-4 mb-5">
                          <div className="card bg-light border-0 h-100">
                              <Link to="/short" className="anonymous-link">
                                  <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4"><i className="bi bi-patch-check"></i></div>
                                      <h2 className="fs-4 fw-bold">Uptime</h2>
                                      <p className="mb-0">Continuous uptime monitoring.</p>
                                  </div>
                              </Link>
                          </div>
                      </div>
*/}
                  </div>
              </div>
            </section>
        </div>
    );
}

export default HomePage;
