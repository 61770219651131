import React, { useState, useEffect } from 'react';
import { API, Auth } from 'aws-amplify'
import { FiCopy } from 'react-icons/fi';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

function EmailPage() {
    const generateRandomString = (length) => {
        const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }
        return randomString;
    }

    const [emailsMsgs, setEmailsMsgs] = useState([]);
    const [emails, setEmails] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isAssociatingAddress, setAssociatingAddress] = useState(false);
    const [isAssociatedAddress, setAssociatedAddress] = useState(false);
    const [associatedEmailAddress, setAssociatedEmailAddress] = useState('');
    const [isLoadingEmails, setLoadingEmails] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState(null);

    function formatDate(timestamp) {
        const date = new Date(timestamp * 1000); // Convert Unix timestamp to JavaScript date object
        return format(date, 'MMMM dd, yyyy HH:mm:ss');
    }

    async function Emails(method) {
        let data = {};
        setLoadingEmails(true);

        try {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const requestInfo = {
              headers: { Authorization: token }
            }

            switch (method) {
                case Methods.GET:
                    data = await API.get('sed', '/emails', requestInfo)
                    break;
                default:
                    console.log("Unknown or unsupported request method");
            }
        } catch (error) {
            console.log(error);
        }

        setLoadingEmails(false);
        return data;
    }

    async function Associate(method, payload) {
        let response = {status: 0, data: null};

        try {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const requestInfo = {
              headers: { Authorization: token },
              body: payload
            }

            switch (method) {
                case Methods.POST:
                    response.data = await API.post('sed', '/associate', requestInfo)
                    response.status = 200;
                    break;
                default:
                    break;
            }
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 409:
                        response.status = 409;
                        break;
                    default:
                        console.error(error);
                }                
            }
        }

        return response;
    }    

    const Methods = Object.freeze({
        GET:   Symbol("get"),
        POST:  Symbol("post")
    });

    async function Email(method, payload) {
        let data = {};

        try {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const requestInfo = {
              headers: { Authorization: token },
            }

            switch (method) {
                case Methods.GET:
                    const queryString = new URLSearchParams(payload).toString();
                    data = await API.get('sed', `/email?${queryString}`, requestInfo);
                        break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);
        }

        return data;
    }
       
    const isValidEmail = (email) => {
        // Regular expression to match a valid email address
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    
        // Test the input email against the regex
        return emailRegex.test(email);
    };    

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    async function associateAddress() {
        if (isValidEmail(inputValue)) {
            setAssociatingAddress(true);
            setAssociatedAddress(false);
            try {
                const payloadForPost = {
                    email_address: inputValue,
                };

                // Calling the Url function with a POST method and the payload
                const result = await Associate(Methods.POST, payloadForPost);
                switch(result.status) {
                    case 200:
                        setInputValue(generateRandomString(5) + '@sed.sh');
                        break;
                    case 409:
                        setInputValue(generateRandomString(5) + '@sed.sh');
                        break;
                    default:
                        alert(result);
                }
                copyToClipboard(inputValue);
                setAssociatedEmailAddress(inputValue);
                Emails(Methods.GET)
                .then(response => {
                    const sortedEmailsWithMsgs = sortEmailsByTimestamp(response);
                    setEmailsMsgs(sortedEmailsWithMsgs);

                    const sortedEmailsAlphabetically = sortEmailsAlphabetically(response);
                    setEmails(sortedEmailsAlphabetically);                        
                })
                .catch(error => {
                // Handle error
                });
            } catch (error) {
                console.log(error);
            }
            setAssociatingAddress(false);
            setAssociatedAddress(true)
        }
    }  

    const EmailTile = ({ address, onClick, isSelected }) => {
        const tileStyle = {
            cursor: 'pointer',
            margin: '5px',
            padding: '10px',
            border: '1px solid #ccc',
            backgroundColor: isSelected ? '#dedede' : 'transparent'
        };
    
        return (
            <div 
                onClick={() => onClick(address)} 
                style={tileStyle}>
                {address}
            </div>
        );
    };
    
    
    function sortEmailsByTimestamp(emailHash) {
        let allEmails = [];
        for (const email in emailHash) {
            // Check if the value of the key is not null
            if (emailHash[email] != null) {
                // Assuming emailHash[email] is an array; no need to filter item != null
                allEmails = allEmails.concat(emailHash[email]);
            }
        }
        allEmails.sort((a, b) => b.timestamp - a.timestamp);
        return allEmails;
    }

    function sortEmailsByTimestamp(emailHash) {
        let allEmails = [];
        for (const email in emailHash) {
            // Check if the value of the key is not null
            if (emailHash[email] != null) {
                // Assuming emailHash[email] is an array; no need to filter item != null
                allEmails = allEmails.concat(emailHash[email]);
            }
        }
        allEmails.sort((a, b) => b.timestamp - a.timestamp);
        return allEmails;
    }
    
    function sortEmailsAlphabetically(emailHash) {
        // Extract keys (email addresses) into an array
        const emailAddresses = Object.keys(emailHash);
    
        // Sort the array alphabetically
        emailAddresses.sort((a, b) => a.localeCompare(b));
    
        return emailAddresses;
    }
    

    useEffect(() => {
        setInputValue(generateRandomString(5) + '@sed.sh');
        Auth.currentAuthenticatedUser()
            .then(() => {
                setIsAuthenticated(true);
                Emails(Methods.GET)
                    .then(response => {
                        const sortedEmailsWithMsgs = sortEmailsByTimestamp(response);
                        setEmailsMsgs(sortedEmailsWithMsgs);

                        const sortedEmailsAlphabetically = sortEmailsAlphabetically(response);
                        setEmails(sortedEmailsAlphabetically);                        
                    })
                    .catch(error => {
                        console.log(error); 
                    });
            })
            .catch(() => {
                console.log("not authenticated");
                setIsAuthenticated(false);
            });  
    }, []);
    
    function copyToClipboard(text) {
        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
    
        // Select the text
        textarea.select();
    
        try {
            // Copy the text inside the textarea
            document.execCommand('copy');
        } catch (err) {
            console.error('Failed to copy text', err);
        }
    
        // Cleanup: remove the temporary textarea
        document.body.removeChild(textarea);
    }    

    const selectText = (event) => {
        const element = event.target;
        if (document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
        } else if (window.getSelection) {
            const range = document.createRange();
            range.selectNode(element);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
        }
    }

    // Function to handle tile click
    const handleTileClick = (emailAddress) => {
        setSelectedEmail(selectedEmail === emailAddress ? null : emailAddress);
    };
            
    const TableRow = ({ email }) => {
        const [showDetails, setShowDetails] = useState(false);
        const [details, setDetails] = useState(null);
        const [isLoading, setIsLoading] = useState(false);
    
        const fetchDetails = async () => {
            setIsLoading(true);
            try {
                const payload = {
                    msg_id: email.msg_id,
                };
                Email(Methods.GET, payload)
                .then(response => {
                    setDetails(response);
                })
                .catch(error => {
                // Handle error
                });
            } catch (error) {
                console.error('Error fetching details:', error);
                setDetails(null);
            } finally {
                setIsLoading(false);
            }
        };
    
        const handleSubjectClick = () => {
            if (!showDetails) {
                fetchDetails();
            }
            setShowDetails(!showDetails);
        };
    
        return (
            <>
                <tr key={email.msg_id}>
                    <td>{formatDate(email.timestamp)}</td>
                    <td>{email.sender}</td>
                    <td onClick={handleSubjectClick} style={{ cursor: 'pointer' }}>{email.subject}</td>
                    <td></td>
                </tr>
                {showDetails && (
                    <tr>
                        <td colSpan="4" style={{backgroundColor: '#F8F9FA'}}>
                            {isLoading ? (
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: details ? details : 'Loading...' }} />
                                )}
                        </td>
                    </tr>
                )}
            </>
        );
    };    

    // Filter emails based on selected email address
    const filteredEmails = selectedEmail 
        ? emailsMsgs.filter(email => email.email_address === selectedEmail)
        : emailsMsgs;    
        
    // Extract unique email addresses for tiles
    const emailAddresses = [...new Set(emailsMsgs.map(email => email.email_address))];

    return (
        <div>
            <div>
                <div>
                    <header className="py-5">
                        <div className="container px-lg-5">
                            <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                                <div className="m-4 m-lg-5">
                                    <h1 className="display-5 fw-bold">Inbox</h1>
                                    <p className="fs-4">Get instant access to a secure and anonymous random email inbox, ensuring your privacy and keeping your inbox clutter-free</p>
                                    <div className="form-group">
                                        <input type="text" className="form-control" value={inputValue} onClick={selectText} onChange={handleInputChange} disabled={!isAuthenticated} readOnly />
                                    </div>
                                    <br />
                                    <p className="fs-4">
                                        <button type="submit" className="btn btn-primary btn-lg" onClick={associateAddress} disabled={!isAuthenticated}>
                                        {isAssociatingAddress ? (
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Associating Address...</span>
                                                </div>
                                                ) : (
                                                'Associate'
                                            )}
                                        </button>
                                        {isAssociatedAddress ? (<span><br />Email address <strong>{associatedEmailAddress}</strong> copied to clipboard</span>) : (
                                                ''
                                            )}
                                    </p>
                                    {!isAuthenticated && (
                                    <p>
                                        You need to <Link to="/signup">Register</Link> or <Link to="/login">Login</Link> to use this feature. It's free!
                                    </p>
                                    )}
                                    <p>
                                        Please note that emails received in your random email inbox are stored securely for a duration of 7 days from the date of receipt. After this period, the emails will be automatically and permanently deleted from our system to ensure your privacy and data protection.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </header>

                    <section className="pt-4">
                        <div className="container px-lg-5">
                            <div className="row gx-lg-5">
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {emails.map(address => (
                                        <EmailTile 
                                            key={address} 
                                            address={address} 
                                            onClick={handleTileClick} 
                                            isSelected={address === selectedEmail}
                                        />
                                    ))}
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" width="20%">Date</th>
                                            <th scope="col" width="30%">From</th>
                                            <th scope="col">Subject</th>
                                            <th scope="col" width="10%">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {emailsMsgs.length > 0 ? (
                                            emailsMsgs
                                                .filter(email => !selectedEmail || email.email_address === selectedEmail)
                                                .map((email, index) => (
                                                    <TableRow key={email.id || index} email={email} />
                                                ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4" style={{ textAlign: 'center' }}>
                                                    {isLoadingEmails ? (
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden">Loading Emails...</span>
                                                        </div>
                                                    ) : (
                                                        'No Emails Available'
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default EmailPage;

