import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import './App.css';
import { useNavigate } from 'react-router-dom';


function LoginPage() {  
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isConfirmPassword, setisConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [autocomplete, setAutocomplete] = useState('on');

  const handleFocus = () => {
    setAutocomplete('off');
  };

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function isValidConfirmationCode(value) {
    const digitsRegex = /^\d+$/;
  
    if (typeof value === 'string' && digitsRegex.test(value)) {
      return value.length >= 6;
    }
  
    return false;
  }

  function isValidPassword(password) {
    return password.length >= 8;
  }

  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (isValidEmail(value)) {
      setEmail(value);
    }
  };

  const handleConfirmationCodeChange = (e) => {
    const value = e.target.value;

    if (isValidConfirmationCode(value)) {
      setConfirmationCode(value);
    }
  };

    
  const handleForgotPassword = async (e) => {
    e.preventDefault(); 
    setSuccessMessage('');
    setErrorMessage('');
    setIsResetPassword(true);

    try {
      if (isValidEmail(email)) {
        await Auth.forgotPassword(email);
        setIsCodeSent(true);  
      } else {
        setSuccessMessage('');
        setErrorMessage('Reset password failed. Please check whether your email address is valid.');
      }
    } catch (error) {
      console.error('Forgot Password Error', error);
    }

    setIsResetPassword(false);
  };

  const handleConfirmPassword = async (e) => {
    e.preventDefault(); 
    setSuccessMessage('');
    setErrorMessage('');
    setisConfirmPassword(true);

    try {
      if (isValidConfirmationCode(confirmationCode) && isValidEmail(email) && isValidPassword(newPassword)) {
        await Auth.forgotPasswordSubmit(email, confirmationCode, newPassword);
        setisConfirmPassword(false);
        navigate('/login');
      } else {
        setSuccessMessage('');
        setErrorMessage('Reset password failed. Please check whether your confirmation code and password are valid.');
      }
    } catch (error) {
      console.error('Confirm Password Error', error);
    }
    setisConfirmPassword(false);
  };

  return (
    <div>
    <section className="pt-4">
    <div className="container text-center">
  <div className="row">
    <div className="col-sm">
    </div>
    <div className="col-sm">
        <h1>Reset Password</h1><br />

        {isCodeSent ? (
<form autocomplete="off">
<h4>Confirmation code for password reset has been sent to your email address</h4>
<div className="form-outline mb-4">
  <input type="number" className="form-control" placeholder='Confirmation Code' autoComplete={autocomplete} onFocus={handleFocus} onChange={handleConfirmationCodeChange} />
</div>    
<div className="form-outline mb-4">
  <input type="password" className="form-control" placeholder='New Password' autoComplete={autocomplete} onFocus={handleFocus} onChange={(e) => setNewPassword(e.target.value)} />
</div>    

<button onClick={handleConfirmPassword} type="submit" className="btn btn-primary btn-block mb-4">
{isConfirmPassword ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          'Confirm Password'
        )}

 </button>

</form>

      ) : (



        <form>
<h4>Please enter your email address below to reset your password</h4>
  <div className="form-outline mb-4">
    <input type="email" className="form-control" placeholder='Email address' onChange={handleEmailChange} />
  </div>    

  <button onClick={handleForgotPassword} type="submit" className="btn btn-primary btn-block mb-4">
  
  {isResetPassword ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          'Reset Password'
        )}

   </button>

  </form>



   )}


   <p className="success-message">{successMessage}</p>
   <p className="error-message">{errorMessage}</p>


  <div className="text-center">
    <p>Not a member?&nbsp;
        <Link to="/signup">
        Signup
        </Link>
        &nbsp;or&nbsp;
        <Link to="/login">
        Login
        </Link>
    </p>
  </div>























    </div>
    <div className="col-sm">
    </div>
  </div>
</div>

    </section>    
</div>
    

  );
}

export default LoginPage;
