import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify'; // Import your authentication library
import { useNavigate } from 'react-router-dom';

function LogoutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    async function signOut() {
      try {
        // Sign the user out using your authentication library (e.g., AWS Amplify)
        await Auth.signOut();

        // Redirect to the desired page (e.g., home page) after logout
        navigate('/'); // Replace '/' with the desired path
      } catch (error) {
        // Handle any logout errors
        console.error('Logout error:', error);
      }
    }

    signOut();
  }, [navigate]);

  return (
    <div>
      <p>Logging out...</p>
    </div>
  );
}

export default LogoutPage;
