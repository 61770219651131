import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import './App.css';
import { useNavigate } from 'react-router-dom';


function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const handleSignIn = async () => {
    setIsLoggingIn(true);
    const options = {
      remember: rememberMe,
    };

    options.remember = options.remember.toString();

    try {
      await Auth.signIn(email, password, options);
      setIsLoggingIn(false);
      setSuccessMessage('Login successful');
      console.log(await Auth.currentUserInfo());
      setErrorMessage('');
      navigate('/');
    } catch (error) {
      setIsLoggingIn(false);
      setSuccessMessage('');
      setErrorMessage('Login failed. Please check your credentials.');
    }
  }

  const handleGoogleSignIn = async () => {
    try {
      await Auth.federatedSignIn({ provider: 'Google' });
      setIsLoggingIn(false);
      setSuccessMessage('Login successful');
      setErrorMessage('');
      navigate('/');
    } catch (error) {
      console.error('Google sign-in error:', error);
      setIsLoggingIn(false);
      setSuccessMessage('');
      setErrorMessage('Login failed. Please check your credentials.');
    }
  };

  return (
    <div>
    <section className="pt-4">
    <div className="container text-center">
  <div className="row">
    <div className="col-sm">
    </div>
    <div className="col-sm">
        <h1>Login to sed.sh</h1><br />
        <form>

  <div className="form-outline mb-4">
    <input type="email" className="form-control" placeholder='Email address' onChange={(e) => setEmail(e.target.value)} />
  </div>    

  <div className="form-outline mb-4">
    <input type="password" className="form-control" placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
  </div>

  <div className="row mb-4">
    <div className="col d-flex justify-content-center">
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="" id="remember_me" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
        <label className="form-check-label" htmlFor="remember_me"> Remember me </label>
      </div>
    </div>

    <div className="col">
        <Link to="/forgot_password">
            Forgot password?
        </Link>
    </div>
  </div>


  <button onClick={handleSignIn} type="submit" className="btn btn-primary btn-block mb-4" disabled={isLoggingIn}>
        {isLoggingIn ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          'Sign In'
        )}
   </button>
   <p className="success-message">{successMessage}</p>
   <p className="error-message">{errorMessage}</p>


  <div className="text-center">
    <p>Not a member?&nbsp;
        <Link to="/signup">
        Signup
        </Link>
    </p>
  </div>
  </form>

    </div>
    <div className="col-sm">
    </div>
  </div>
</div>

    </section>
    </div>
  );
}

export default LoginPage;
