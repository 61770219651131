import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Short.css';
import { Link } from 'react-router-dom';
import { API, Auth } from 'aws-amplify'
import { format } from 'date-fns';
import { FiCopy } from 'react-icons/fi';

function ShortPage() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [urls, setUrls] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isLoadingUrls, setLoadingUrls] = useState(false);
    const [isAddingUrl, setAddingUrl] = useState(false);
    const [isAddedUrl, setAddedUrl] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleCopyClick = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy);
      };    

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };  
  
    const Methods = Object.freeze({
        GET:   Symbol("get"),
        POST:  Symbol("post")
    });
    
    function validateForm(event) {
        event.preventDefault();
        const urlInput = document.getElementById('textbox');
        const urlPattern = /^(ftp|http|https):\/\/[^ "]+(\.[^ "]+)+[^ "'\n]*$/;

        if (!urlPattern.test(urlInput.value)) {
            alert('Please enter a valid URL.');
            return;
        }
    }

    function selectText(element) {
        if (document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
        } else if (window.getSelection) {
            const range = document.createRange();
            range.selectNode(element);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
        }
    }

    useEffect(() => {
        async function checkAuthenticationStatus() {
            try {
              await Auth.currentAuthenticatedUser();
              setIsAuthenticated(true);
              Urls(Methods.GET)
              .then(response => {
                setUrls(response); // Assuming the response is the array of URL objects
              })
              .catch(error => {
                // Handle error
              });
        
            } catch (error) {
              setIsAuthenticated(false);
            }
          }
      
          checkAuthenticationStatus();
    }, []);

    async function Urls(method) {
        let data = {};
        setLoadingUrls(true);

        try {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const requestInfo = {
              headers: { Authorization: token }
            }

            switch (method) {
                case Methods.GET:
                    data = await API.get('sed', '/urls', requestInfo)
                    break;
                default:
                    console.log("Unknown or unsupported request method");
            }
        } catch (error) {
            console.log(error);
        }

        setLoadingUrls(false);
        return data;
      }

      async function Url(method, payload) {
        let data = {};

        try {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const requestInfo = {
              headers: { Authorization: token },
              body: payload
            }

            switch (method) {
                case Methods.POST:
                    data = await API.post('sed', '/url', requestInfo)
                    console.log(data);
                    break;
                default:
                    console.log("Unknown or unsupported request method");
            }
        } catch (error) {
            console.log(error);
        }

        return data;
      }      

    const isValidUrl = (url) => {
        // Regular expression to match a valid URL
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

        // Test the input URL against the regex
        return urlRegex.test(url);
    };
      
    async function addUrl() {
        if (isValidUrl(inputValue)) {
            setAddingUrl(true);
            setAddedUrl(false);
            try {
                const payloadForPost = {
                    url: inputValue,
                };

                // Calling the Url function with a POST method and the payload
                const result = await Url(Methods.POST, payloadForPost);
                console.log(result);
                navigator.clipboard.writeText(result.id)
                Urls(Methods.GET)
                .then(response => {
                setUrls(response); // Assuming the response is the array of URL objects
                })
                .catch(error => {
                // Handle error
                });
            } catch (error) {
                console.log(error);
            }
            setAddingUrl(false);
            setAddedUrl(true)
        }
      }    

    function formatDate(dateString) {
        const dateObject = new Date(dateString);
        return format(dateObject, 'MMMM dd, yyyy HH:mm:ss');
    }

    const TableRow = ({ url }) => {
        const [isHovered, setIsHovered] = useState(false);
      
        const handleCopyClick = () => {
            navigator.clipboard.writeText(url.id)
            .then(() => {
              console.log(`Copied ${url.id} to clipboard`);
            })
            .catch(err => {
              console.error('Error copying to clipboard:', err);
            });
        };
      
        return (
            <tr key={url.id}>
            <td
              className={`copy-text-container`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <span className={`text-to-copy ${isHovered ? 'hovered' : ''}`}  onClick={handleCopyClick}>{url.id}</span>&nbsp;
              {isHovered && (
                <span className="copy-icon" onClick={handleCopyClick}>
                  <FiCopy />
                </span>
              )}
            </td>
            <td><a href={url.destination_url} target="_blank" rel="noopener noreferrer">{url.destination_url}</a></td>
            <td>{url.clicks_count}</td>
            <td>{formatDate(url.timestamp)}</td>
          </tr>
        );
      };
      

    return (
        <div className="App">
            <header className="py-5">
                <div className="container px-lg-5">
                    <div className="p-4 p-lg-5 bg-light rounded-3 text-center"> 
                        <div className="m-4 m-lg-5">
                            <form onSubmit={validateForm}>
                                <h1 className="display-5 fw-bold">Shortify</h1>
                                <p className="fs-4">Experience effortless URL shortening...</p>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="textbox" placeholder="http://example.com" disabled={!isAuthenticated} value={inputValue} onChange={handleInputChange} required />
                                </div>
                                <br />
                                <p className="fs-4">
                                    <button type="submit" className="btn btn-primary btn-lg" onClick={addUrl} disabled={!isAuthenticated}>
                                        {isAddingUrl ? (
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Adding URL...</span>
                                            </div>
                                            ) : (
                                            'Shortify'
                                        )}
                                    </button>
                                </p>
                                {!isAuthenticated && (
                                    <p>
                                        You need to <Link to="/signup">Register</Link> or <Link to="/login">Login</Link> to use this feature. It's free!
                                    </p>
                                )}
                                {isAddedUrl ? (
                                    <p>
                                        URL successfully shortened and copied to clipboard
                                    </p>
                                ) : (
                                    'URLs generated have a validity period of 30 days from the date of creation. After this period, the URLs will be automatically removed and will no longer be accessible.'
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </header>

            <section className="pt-4">
                <div className="container px-lg-5">
                    <div className="row gx-lg-5">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Shortified URL</th>
                                    <th scope="col">Destination URL</th>
                                    <th scope="col">Clicks Count</th>
                                    <th scope="col">Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {urls.length > 0 ? (
                                    urls.map(url => (
                                    <TableRow key={url.id} url={url} />
                                    ))
                                ) : (
                                    <tr>
                                    <td colSpan="4">
                                        {isLoadingUrls ? (
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading URLs...</span>
                                        </div>
                                        ) : (
                                        'No URLs available'
                                        )}
                                    </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ShortPage;

