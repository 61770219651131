import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import './App.css';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';


function SignupPage() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [password, setPassword] = useState('');
  const [passwordVerification, setPasswordVerification] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [isSignup, setIsSignup] = useState(false);

  const handleSignUp = async () => {
    setIsSignup(true);

    setTimeout(function(){
      //do what you need here
  }, 5000);  

    try {
      if (password !== passwordVerification) {
        setErrorMessage('Passwords do not match');
        return;
      }

      // Verify the reCAPTCHA response
      if (!recaptchaValue) {
        setErrorMessage('Please complete the reCAPTCHA verification');
        return;
      }

      // Use Amplify Auth.signUp to initiate the sign-up process
      const signUpResponse = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
        },
      });

      setIsSignup(false);
      setStep(2);
    } catch (error) {
      setIsSignup(false);
      setErrorMessage('Error signing up');
    }
  };

  return (
    <div>
      <section className="pt-4">
        <div className="container text-center">
          <div className="row">
            <div className="col-sm"></div>
            {step === 1 && (
              <div className="col-sm">
                <h1>Signup to sed.sh</h1><br />
                <form>
                  <div className="form-outline mb-4">
                    <input type="email" className="form-control" autoComplete="off" placeholder='Email address' onChange={(e) => setEmail(e.target.value)} />
                  </div>    
                  <div className="form-outline mb-4">
                    <input type="password" className="form-control" autoComplete="off" placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <div className="form-outline mb-4">
                    <input type="password" className="form-control" autoComplete="off" placeholder='Repeat Password' onChange={(e) => setPasswordVerification(e.target.value)} />
                  </div>
                  <ReCAPTCHA sitekey="6Lfj7xApAAAAAKkeSNWdiotDrm-ZyBZ6wdzrSxr3" onChange={(value) => setRecaptchaValue(value)} /><br />
                  <button type="button" className="btn btn-primary btn-block mb-4" onClick={handleSignUp}>
                    {isSignup ? (
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      'Signup'
                    )}
                  </button>
                  <p className="success-message">{successMessage}</p>
                  <p className="error-message">{errorMessage}</p>
                  <div className="text-center">
                    <p>
                      Already a member?&nbsp;
                      <Link to="/login">Login</Link>
                    </p>
                  </div>
                </form>
              </div>
            )} {step === 2 && (
              <div className="col-sm">
                <h1>Signup to sed.sh</h1><br />
                <p>
                  We have sent a verification link to your email address. Please activate your account.
                </p>
                <form>
                  <Link to="/login">
                    <button type="button" className="btn btn-primary btn-block mb-4">
                      Continue
                    </button>
                  </Link>
                </form>
              </div>
            )}
            <div className="col-sm"></div>
          </div>
        </div>
      </section>    
    </div>
  );
}

export default SignupPage;
