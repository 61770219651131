import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Home';
import ShortPage from './Short';
import UptimePage from './Uptime';
import RoutePage from './Route';
import EmailPage from './Email';
import AboutPage from './About';
import LoginPage from './Login';
import LogoutPage from './Logout';
import SignupPage from './Signup';
import ForgotPasswordPage from './ForgotPassword';
import Navigator from './Navigator'
import Footer from './Footer'

function App() {
  return (
    <Router>
      <div>
        <Navigator />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/short" element={<ShortPage />} />
          <Route path="/uptime" element={<UptimePage />} />
          <Route path="/route" element={<RoutePage />} />
          <Route path="/email" element={<EmailPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/forgot_password" element={<ForgotPasswordPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
