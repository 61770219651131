import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function AboutPage() {
    return (
        <div>
            <section class="pt-4">
                <div class="container px-lg-5">
                    <div class="p-4 p-lg-5 bg-light rounded-3 text-left">
                        <div class="m-4 m-lg-5">
                            <p class="fs-4">sed.sh, created by developers for developers, is a dedicated platform designed to empower developers with a comprehensive suite of versatile tools.</p>
                            <p class="fs-4">Our mission is to simplify, streamline, and secure your workflow, offering a wide range of services such as URL shortening, random email inboxes, file sharing, and more.</p>
                            <p class="fs-4">With a user-friendly interface and seamless integration, sed.sh serves as the ultimate Swiss Army Knife for developers.</p>
                            <p class="fs-4">We understand the unique needs and challenges of developers, and our platform is meticulously crafted to enhance your productivity and efficiency.</p>
                            <p class="fs-4">Join us today and experience the convenience and power of sed.sh - the go-to toolkit for developers.</p>
                        </div>
                    </div>

                    <div class="p-4 p-lg-5 bg-light rounded-3 text-center">
                        <Link to="/" className="anonymous-link">
                            <span class="btn btn-primary btn-lg">Go to home</span>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AboutPage;


