import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

function Navigator() {
    const [userGreeting, setUserGreeting] = useState(null);

    useEffect(() => {
        const fetchUserGreeting = async () => {
          try {
            const user = await Auth.currentUserInfo();
            console.log(user.attributes.email);
            setUserGreeting(user.attributes.email);
          } catch (error) {
            setUserGreeting("");
          }
        };
    
        fetchUserGreeting();
      }, []);
        
  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container px-lg-5">
                <Link to="/" className="anonymous-link">
                <span className="navbar-brand">sed.sh</span>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <Link to="/" className="anonymous-link">
                        <li className="nav-item"><span className="nav-link active" aria-current="page">Home</span></li>
                        </Link>
                        <Link to="/about" className="anonymous-link">
                        <li className="nav-item"><span className="nav-link">About</span></li>
                        </Link>
                        <li className="nav-item"><span className="nav-link">{userGreeting}</span></li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
  );
}

export default Navigator;
